import axios from "axios";
import { useIntl } from "react-intl";
import React, { useEffect, useState } from "react";
import { Card, HeaderTitle, Loader } from "../../components";
import { useSearchParams } from "react-router-dom";

import { ReactComponent as SearchNormalIcon } from "../../assets/icons/search-normal.svg";

function Search() {
  const intl = useIntl();
  let [loading, setLoading] = useState(true);
  let [searchParams, setSearchParams] = useSearchParams();

  // FILTERS
  let q = searchParams.get("q") || "";
  let page = parseInt(searchParams.get("page"), 10) || 1;
  let pageSize = parseInt(searchParams.get("pageSize"), 10) || 10;

  let [products, setProducts] = useState([]);
  const [paginationData, setPaginationData] = React.useState({
    count: 1,
    page: 1,
    pageSize: 10,
    totalPageCount: 1,
  });

  useEffect(() => {
    setLoading(true);
    if (q.length <= 2) {
      setProducts([]);
      setPaginationData({
        count: 1,
        page: 1,
        pageSize: 10,
        totalPageCount: 1,
      });
      return;
    }

    axios
      .post(`${process.env.REACT_APP_API_BASE}/search`, {
        q: q,
        page,
        pageSize,
      })
      .then((res) => {
        setProducts(res.data.products);

        setPaginationData({
          count: res.data.pagination.count,
          page: res.data.pagination.page,
          pageSize: res.data.pagination.pageSize,
          totalPageCount: Math.ceil(
            res.data.pagination.count / res.data.pagination.pageSize
          ),
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [q]);

  const onSearchKeyup = (e) => {
    setSearchParams({ q: e.target.value, page: 1, pageSize: 10 });
  };

  const onMoreClick = (e) => {
    setSearchParams({ q, page, pageSize, page: page + 1 });

    axios
      .post(`${process.env.REACT_APP_API_BASE}/search`, {
        q: q,
        page: page + 1,
        pageSize,
      })
      .then((res) => {
        let nextProducts = products.map((x) => x);
        nextProducts.push(...res.data.products);
        setProducts(nextProducts);

        setPaginationData({
          count: res.data.pagination.count,
          page: res.data.pagination.page,
          pageSize: res.data.pagination.pageSize,
          totalPageCount: Math.ceil(
            res.data.pagination.count / res.data.pagination.pageSize
          ),
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="es-home pb-0">
      <div className="container">
        <HeaderTitle
          title={intl.formatMessage({ id: "COMMON.SEARCH" })}
          link={true}
          linkUrl="/"
        />
      </div>

      <div className="container mt-3">
        <div
          className="es-search-input input-group flex-nowrap"
          style={{ height: "52px" }}
        >
          <input
            type="text"
            className="es-input form-control"
            placeholder={intl.formatMessage({ id: "COMMON.SEARCH" })}
            aria-label="Username"
            aria-describedby="addon-wrapping"
            onKeyUp={onSearchKeyup}
            defaultValue={q}
          />
          <span
            className="es-input-search input-group-text"
            id="addon-wrapping"
          >
            <SearchNormalIcon />
          </span>
        </div>
      </div>

      <section className="es-products-main">
        <div className="container">
          {q.length <= 2 ? (
            <h2 className="mt-3">
              {intl.formatMessage({ id: "COMMON.SEARCH_MIN_SYMB" })}
            </h2>
          ) : (
            ""
          )}

          {products.length == 0 && !loading ? (
            <h2 className="mt-3">
              {intl.formatMessage({ id: "COMMON.NOTHING_FOUND" })}
            </h2>
          ) : null}

          {q.length > 2 && loading ? (
            <div className="position-relative" style={{ height: 100 }}>
              <Loader />
            </div>
          ) : (
            ""
          )}

          {products.length > 0 ? (
            <>
              <h2>{intl.formatMessage({ id: "COMMON.RESULT" })}</h2>
              <div className="row">
                {products.map((product) => (
                  <div className="col-6" key={"prod-" + product.id}>
                    <Card product={product} />
                  </div>
                ))}
              </div>
            </>
          ) : (
            ""
          )}
        </div>

        {page < paginationData.totalPageCount ? (
          <div className="container">
            <div className="row">
              <div className="col-12 mt-3">
                <button
                  className="btn es-btn-secondary w-100"
                  type="button"
                  onClick={onMoreClick}
                >
                  {intl.formatMessage({ id: "COMMON.LOAD_MORE" })}
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
    </div>
  );
}

export default Search;
