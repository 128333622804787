import axios from "axios";
import moment from "moment/moment";
import { useIntl } from "react-intl";
import React, { useEffect, useState } from "react";

import { HeaderTitle, Loader } from "../../components";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { convertToPrice } from "../../helpers/general";

function HistoryOrders() {
  const intl = useIntl();

  const [data, setData] = useState([]);
  const [user, setUser] = useLocalStorage("user", {});
  let [loading, setLoading] = useState(true);

  const getOrders = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE}/get-orders`,
        {
          id: user.id,
        }
      );
      setData(res.data.orders);
      setLoading(false);
    } catch (err) {
      console.log("error", err);
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    getOrders();
  }, []);

  if (loading) return <Loader />;

  return (
    <div className="es-history-orders">
      <div className="container">
        <HeaderTitle
          title={intl.formatMessage({ id: "COMMON.HISTORY_ORDERS" })}
          link={true}
          linkUrl={"/profile"}
        />
      </div>
      <div className="es-history-orders-filters">
        <button className="btn active">
          {intl.formatMessage({ id: "COMMON.ALL" })}
        </button>
        {/* <button className="btn">Созданные</button>
        <button className="btn">В пути</button>
        <button className="btn">Собираются</button> */}
      </div>
      <hr />
      <div className="container">
        <div className="es-history-orders-items">
          {data.map((d) => (
            <>
              <div className="es-history-orders-item">
                <div className="es-history-orders-flex">
                  <h4>№</h4>
                  <p>{d.id}</p>
                </div>
                <div className="es-history-orders-flex">
                  <h4>{intl.formatMessage({ id: "COMMON.DATE" })}</h4>
                  <p>{moment(d.date).format("DD-MM-YYYY")}</p>
                </div>
                <div className="es-history-orders-flex">
                  <h4>{intl.formatMessage({ id: "COMMON.PAYMENT_METHOD" })}</h4>
                  <p>{d.payment_method}</p>
                </div>
                <div className="es-history-orders-flex">
                  <h4>{intl.formatMessage({ id: "COMMON.STATUS" })}</h4>
                  <p>{d.status}</p>
                </div>
                <div className="es-history-orders-flex">
                  <h4>{intl.formatMessage({ id: "COMMON.SUMMA" })}</h4>
                  <p>
                    {convertToPrice(Number(d.total))}{" "}
                    {intl.formatMessage({ id: "COMMON.SUM" })}
                  </p>
                </div>
              </div>
              <hr />
            </>
          ))}
          {data.length > 0 || (
            <div className="es-history-orders-notifaction">
              <h4>
                {intl.formatMessage({ id: "COMMON.THERE_ARE_NO_ORDERS_YET" })}
              </h4>
              <p>{intl.formatMessage({ id: "COMMON.HISTORY_ORDERS_INFO" })}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default HistoryOrders;
