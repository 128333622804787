import axios from "axios";
import Swal from "sweetalert2";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import withReactContent from "sweetalert2-react-content";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { setCartReducer } from "../../stores/baseSlice";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { Card, HeaderTitle, Loader } from "../../components";
import {
  convertToPrice,
  getMediaPATH,
  getProductName,
  sortSizes,
} from "../../helpers/general";

function Products() {
  const intl = useIntl();
  let { id } = useParams();
  let { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const [cart, setCart] = useLocalStorage("cart", []);
  const [recentProducts, setRecentProducts] = useLocalStorage(
    "recentProducts",
    []
  );

  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});
  const [sizes, setSizes] = useState([]);
  const [universalSizes, setUniversalSizes] = useState([]);
  const [images, setImages] = useState([]);
  const [activeSizeTab, setActiveSizeTab] = useState("us");
  const [loading, setLoading] = useState(true);

  // QUERY
  const queryParams = new URLSearchParams(state.state);
  let categoryId = queryParams.get("categoryId");
  let page = queryParams.get("page");
  let pageSize = queryParams.get("pageSize");
  let sort = queryParams.get("sort");
  let typesFilter = queryParams.get("typesFilter");
  let sizesFilter = queryParams.get("sizesFilter");
  let collectionsFilter = queryParams.get("collectionsFilter");

  const buildQueryParams = () => {
    let query = `?categoryId=${categoryId}&page=${
      // page - 1
      0
    }&pageSize=${pageSize}`;

    if (!!sort) query += `&sort=${sort}`;
    if (!!typesFilter) query += `&typesFilter=${typesFilter}`;
    if (!!sizesFilter) query += `&sizesFilter=${sizesFilter}`;
    if (!!collectionsFilter) query += `&collectionsFilter=${collectionsFilter}`;

    return query;
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE}/get-product/${id}`)
      .then((res) => {
        // useDispatch(setBaseData(res.data));
        setProduct(res.data.product);
        setSizes(sortSizes(res.data.sizes));
        setUniversalSizes(sortSizes(res.data.universalSizes));
        setImages(res.data.images);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        return navigate("/");
      });
  }, [id]);

  // ADDING TO RECENT PRODUCT
  useEffect(() => {
    if (!(product || {}).id) return;

    let nextRecentProducts = recentProducts.map((x) => x);
    let isAdded = nextRecentProducts.find((x) => x.article == product.article);
    if (!!isAdded) {
      nextRecentProducts = nextRecentProducts.filter(
        (x) => x.article != product.article
      );
    }

    nextRecentProducts.unshift({ ...product, images: images });
    nextRecentProducts = nextRecentProducts.slice(0, 6);
    return setRecentProducts(nextRecentProducts);
  }, [product]);

  const addToCart = (e) => {
    // SETTING TO LOCALSTORAGE
    let nextCart = JSON.parse(JSON.stringify(cart || []));

    let prIdx = nextCart.findIndex((x) => x.id == product.id);

    if ((product.quantity || 1) < ((nextCart[prIdx] || {}).q || 0) + 1) {
      withReactContent(Swal).fire({
        position: "top-end",
        icon: "error",
        title: intl.formatMessage({ id: "COMMON.PRODUCT_QUANTITY_OVER" }),
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    if (prIdx > -1) {
      nextCart[prIdx].q++;
    } else {
      nextCart.push({
        id: product.id,
        q: 1,
      });
    }

    setCart(nextCart);
    dispatch(setCartReducer(nextCart));
    // END OF SETTING TO LOCALSTORAGE

    // ALERT
    withReactContent(Swal).fire({
      position: "top-end",
      icon: "success",
      title: intl.formatMessage({ id: "COMMON.ADDED_TO_CART" }),
      showConfirmButton: false,
      timer: 3000,
    });
  };
  const isProductInCart = (id) => {
    return !!cart.find((x) => x.id == product.id);
  };

  const getTotalPrice = () => {
    return products.reduce((acc, curr) => acc + curr.q * curr.current_price, 0);
  };

  useEffect(() => {
    if (!cart.length) {
      setProducts([]);
      return;
    }

    axios
      .post(`${process.env.REACT_APP_API_BASE}/get-products/by-id`, {
        ids: cart.map((x) => x.id),
      })
      .then((res) => {
        let products = res.data.products;

        for (let product of products) {
          let cartItem = cart.find((x) => x.id == product.id);
          product.q = cartItem.q;
        }
        setProducts(products);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [cart]);

  if (loading) return <Loader />;

  return (
    <div className="es-product">
      <div className="container">
        <HeaderTitle
          title={`${getProductName(
            product,
            intl.formatMessage({ id: "COMMON.LOCAL_PRODUCT" })
          )}`}
          link={true}
          linkUrl={
            (searchParams.get("categoryId") || "").length > 0
              ? `/products/${buildQueryParams()}`
              : ""
          }
        />
      </div>
      <section>
        <div className="container">
          <div className="es-product-slider">
            <div className="es-product-image">
              <Swiper
                pagination={true}
                modules={[Pagination]}
                className="mySwiper"
              >
                {images.map((image, i) => (
                  <SwiperSlide key={"image-" + i}>
                    <img
                      // src={getMediaPATH("products", image.image)}
                      src={image.url}
                      alt={getProductName(product)}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="es-absolute-box">
                {product.discount_percent > 0 && (
                  <div className="es-product-sale">
                    {product.discount_percent}%
                  </div>
                )}
                {product.is_new_collection == 1 && (
                  <div className="es-product-new">new</div>
                )}
              </div>
            </div>
          </div>
          <div className="es-product-info">
            <p>
              {intl.formatMessage({ id: "COMMON.ARTICLE" })}: {product.article}
            </p>
            <h4 className="text-capitalize">
              {getProductName(
                product,
                intl.formatMessage({ id: "COMMON.LOCAL_PRODUCT" })
              )}
            </h4>
            <div className={`es-product-price`}>
              <h3
                className={`${product.discount_percent > 0 && "es-text-red"}`}
              >
                {convertToPrice(Math.round(product.current_price))}{" "}
                {intl.formatMessage({ id: "COMMON.SUM" })}
              </h3>
              {product.discount_percent > 0 && (
                <p>
                  <span>
                    {convertToPrice(Math.round(product.retail_price))}
                  </span>{" "}
                  {intl.formatMessage({ id: "COMMON.SUM" })}
                </p>
              )}
            </div>
          </div>
          <hr />
          <div className="es-product-size">
            <div className="es-product-size-header">
              <h5>{intl.formatMessage({ id: "COMMON.CHOOSE_SIZE" })}</h5>
              <div className="d-flex">
                <h4
                  className={`${activeSizeTab == "us" ? "active" : ""}`}
                  onClick={() => setActiveSizeTab("us")}
                >
                  US
                </h4>
                <h4
                  className={`${activeSizeTab == "eu" ? "active" : ""}`}
                  onClick={() => setActiveSizeTab("eu")}
                >
                  EU
                </h4>
              </div>
            </div>
            <div className="es-product-sizes">
              {sizes
                .filter((x) => x.title == activeSizeTab)
                .map((size, i) => (
                  <Link
                    key={"size-" + i}
                    className="text-decoration-none"
                    to={`/product/${size.id}?categoryId=${searchParams.get(
                      "categoryId"
                    )}`}
                    state={{ state: state.state }}
                  >
                    <div
                      className={`es-product-size-box ${
                        product.size == size.size ? "active" : ""
                      }`}
                    >
                      {size.value}
                    </div>
                  </Link>
                ))}
              {sizes.length == 0 &&
                universalSizes.map((size, i) => (
                  <Link
                    key={"universalSize-" + i}
                    className="text-decoration-none"
                    to={`/product/${size.id}?categoryId=${searchParams.get(
                      "categoryId"
                    )}`}
                    state={{ state: state.state }}
                  >
                    <div
                      className={`es-product-size-box ${
                        product.size == size.size ? "active" : ""
                      }`}
                    >
                      {size.size}
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        </div>
      </section>
      {recentProducts.length > 0 && (
        <section className="es-products-main">
          <div className="container">
            <h2>{intl.formatMessage({ id: "COMMON.WATCHED" })}</h2>
            <div className="row">
              {recentProducts
                .map((x) => x)
                .filter((x, i) => i != 0)
                .map((product) => (
                  <div className="col-6" key={"product-" + product.id}>
                    <Card product={product} state={state.state} />
                  </div>
                ))}
            </div>
          </div>
        </section>
      )}

      <div className="es-window-button">
        <div className="container">
          <button
            className="es-btn-main btn w-100"
            type="button"
            onClick={addToCart}
          >
            {isProductInCart(product.id) ? (
              <label className="mx-auto">
                {intl.formatMessage({ id: "COMMON.IN_THE_BASKED" })}
              </label>
            ) : (
              <label>
                {intl.formatMessage({ id: "COMMON.INTO_A_BASKED" })}
              </label>
            )}

            {product.retail_price && !isProductInCart(product.id) ? (
              <span> {convertToPrice(Math.round(product.retail_price))}</span>
            ) : null}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Products;
