import React from "react";
import { HeaderTitle } from "../../components";
import { setLanguage, useLang } from "../../i18n/Metronici18n";

function Language() {
  const locale = useLang();

  const setLang = (lang) => {
    setLanguage(lang);
  };
  return (
    <div className="es-language">
      <div className="container">
        <HeaderTitle title="Язык" link={true} linkUrl={"/profile"} />
        <div className="es-language-btns">
          <button
            className={`btn ${locale == "ru" ? "active" : ""}`}
            onClick={(_) => setLang("ru")}
          >
            Русский язык
          </button>
          <button
            className={`btn ${locale == "uz" ? "active" : ""}`}
            onClick={(_) => setLang("uz")}
          >
            O'zbek tili
          </button>
          <button
            className={`btn ${locale == "en" ? "active" : ""}`}
            onClick={(_) => setLang("en")}
          >
            English language
          </button>
        </div>
      </div>
    </div>
  );
}

export default Language;
