import { createSlice } from "@reduxjs/toolkit";

export const baseSlice = createSlice({
  name: "base",
  initialState: {
    settings: [],
    banners: [],
    rootCategories: [],
    bestsellerProducts: [],
    cart: [],
    regions: [],
    districts: [],
  },
  reducers: {
    setBaseData: (state, action) => {
      state.settings = action.payload.settings || [];
      state.banners = action.payload.banners || [];
      state.rootCategories = action.payload.rootCategories || [];
      state.bestsellerProducts = action.payload.bestsellerProducts || [];
      state.regions = action.payload.regions || [];
      state.districts = action.payload.districts || [];
    },
    setCartReducer: (state, action) => {
      state.cart = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBaseData, setCartReducer } = baseSlice.actions;

export default baseSlice.reducer;
