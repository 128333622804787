import axios from "axios";
import * as Yup from "yup";
import moment from "moment";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  FullscreenControl,
  Map,
  Placemark,
  SearchControl,
  YMaps,
  ZoomControl,
  Polygon,
} from "@pbe/react-yandex-maps";

import { HeaderTitle, Loader } from "../../components";
import { convertToPrice } from "../../helpers/general";
import { useLocalStorage } from "../../hooks/useLocalStorage";

// ICONS
import { ReactComponent as AddSquareIcon } from "../../assets/icons/add-square.svg";
// import { ReactComponent as TickSquareIcon } from "../../assets/icons/tick-square.svg";
import { ReactComponent as TickSquareIcon } from "../../assets/images/complete.svg";
import { ReactComponent as SearchNormalIcon } from "../../assets/icons/search-normal.svg";
import polygonJson from "../../assets/polygon.json";
import { useSelector } from "react-redux";

const tg = window.Telegram.WebApp;

let tgConverted = JSON.parse(
  '{"' +
    (((window.Telegram || {}).WebApp || {}).initData || "")
      .replace(/&/g, '","')
      .replace(/=/g, '":"') +
    '"}',
  function (key, value) {
    return key === "" ? value : decodeURIComponent(value);
  }
);
// let tgConverted = {};

function PlacingOrder() {
  const intl = useIntl();
  const navigate = useNavigate();

  const regions = useSelector((state) => state.base.regions);
  const districts = useSelector((state) => state.base.districts);

  // STATES
  const [user, setUser] = useLocalStorage("user", {});
  const [cart, setCart] = useLocalStorage("cart", []);
  const [products, setProducts] = useState([]);
  const [step, setStep] = useState({
    id: 1,
    title: intl.formatMessage({ id: "COMMON.PLACING_AN_ORDER" }),
  });
  const [order, setOrder] = useState({});
  const [coords, setCoords] = useState([]);

  const [deliveryPrice, setDeliveryPrice] = useState(null);
  const [total, setTotal] = useState(0);
  let [loading, setLoading] = useState([]);

  const [branch, setBranch] = useState("");
  const [areaId, setAreaId] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [districtId, setDistrictId] = useState(0);
  const [street, setStreet] = useState("");
  const [home, setHome] = useState("");
  const [homeCode, setHomeCode] = useState("");
  const [apartment, setApartment] = useState("");
  const [point, setPoint] = useState("");
  const [remember_address, setRememberAddress] = useState("");
  const [comment, setComment] = useState("");
  const [address, setAddress] = useState("");
  const [payment, setPayment] = useState("cash");
  const [isLoadingDelPrice, setIsLoadingDelPrice] = useState(false);

  const [defaultMapLocation, setDefaultMapLocation] = useState({
    latitude: 41.31065,
    longitude: 69.279608,
  });

  // FORMIK
  const validationSchema = Yup.object().shape({
    payment: Yup.string(),
  });

  let initialValues = {
    payment: "cash",
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_BASE}/update-account`,
          {
            id: values.id,
            name: values.name,
            birthdate: values.birthdate,
            sex: values ? 1 : 2,
          }
        );
      } catch (err) {
        console.log("error", err);
        return;
      }
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (!cart.length) {
      setProducts([]);
      navigate("/");
      return;
    }

    axios
      .post(`${process.env.REACT_APP_API_BASE}/get-products/by-id`, {
        ids: cart.map((x) => x.id),
      })
      .then((res) => {
        let products = res.data.products;

        for (let product of products) {
          let cartItem = cart.find((x) => x.id == product.id);
          product.q = cartItem.q;
        }
        setProducts(products);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [cart]);
  useEffect(() => {
    let city = regions.find((x) => x.id == cityId);
    let district = districts.find((x) => x.id == districtId);
    setAddress(
      `${(city || {}).name_ru || ""} ${(district || {}).name_ru || ""} ${
        street || ""
      }`.trim()
    );
  }, [cityId, districtId, street]);
  useEffect(() => {
    if (!user.id || !user.phone || !user.name || !user.customer_id) {
      navigate("/");
      return;
    }
  }, [user]);

  // METHODS
  const getTotalPrice = () => {
    let totalOverview = products.reduce(
      (acc, curr) => acc + curr.q * curr.current_price,
      0
    );

    // setTotal(totalOverview);
    return totalOverview;
  };

  useEffect(() => {
    // axios
    //   .post(`${process.env.REACT_APP_API_BASE}/get-delivery-price`, {
    //     total: getTotalPrice(),
    //   })
    //   .then((res) => {
    //     let deliveryPrice = res.data.deliveryPrice || 0;
    //     setDeliveryPrice(deliveryPrice);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });

    if (!cityId) return;

    let city = regions.find((x) => x.id == cityId);
    let district = districts.find((x) => x.id == districtId);

    let latitude = (city || {}).latitude || "0";
    let longitude = (city || {}).longitude || "0";
    if (cityId == 13 && !!district) {
      latitude = (district || {}).latitude || "0";
      longitude = (district || {}).longitude || "0";
    }

    let url = `https://nike.botagent.uz/v1/api/delivery-price?from_latitude=41.322606&from_longitude=69.282702&to_latitude=${latitude}&to_longitude=${longitude}&region_id=${cityId}`;
    if (districtId) url += `&district_id=${districtId}`;

    setIsLoadingDelPrice(true);
    axios
      .get(url)
      .then((res) => {
        let deliveryPrice = parseInt(res.data.price || 0, 10);
        setDeliveryPrice(deliveryPrice);
        setIsLoadingDelPrice(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoadingDelPrice(false);
      });
  }, [getTotalPrice(), cityId, districtId]);

  const createOrder = async (event) => {
    event.preventDefault();

    if (
      !cityId ||
      (cityId == 13 && !districtId) ||
      deliveryPrice == null ||
      !address
    ) {
      withReactContent(Swal).fire({
        position: "top-end",
        icon: "error",
        title: intl.formatMessage({ id: "COMMON.FILL_ALL_FIELDS" }),
        showConfirmButton: false,
        timer: 1500,
      });

      return;
    }

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE}/create-order`,
        {
          account_id: user.id,
          branch_id: branch.id,
          system: "WebApp",
          name: user.name,
          phone: user.phone,
          address: address,
          latitude: defaultMapLocation.latitude,
          longitude: defaultMapLocation.longitude,
          home: home,
          apartment: apartment,
          home_code: homeCode,
          point: point,
          remember_address: 0,
          comment: comment,
          delivery_price: deliveryPrice,
          payment_method: payment,
          items: cart,
          region_id: cityId,
          district_id: districtId,
        }
      );

      setOrder(res.data.order);
      setDeliveryPrice(res.data?.deliveryPrice);
      setAddress(res.data?.address);
      setBranch(res.data.branch);

      // RAXMAT PAYMENT
      if (payment == "rhmt") {
        const raxmatRes = await axios.post(
          `https://nike.botagent.uz/v1/api/get-rahmat-url?id=${res.data.order.id}`,
          {},
          {}
        );

        if (!!(raxmatRes.data || {}).url) {
          await axios.get(
            `https://api.telegram.org/bot1632951540:AAFtLyK6SELFUqCrrMJrjhGrdb9KnbnJyu4/sendMessage?chat_id=${
              JSON.parse(tgConverted.user).id
            }&text=Спасибо за ваш заказ №${
              res.data.order.id
            }&parse_mode=HTML`
            // }%0A%0AНажмите «Оплатить», чтобы перейти к оплате и завершить покупку.&parse_mode=HTML&reply_markup={"inline_keyboard": [[{"text": "Оплатить", "url": "${
            //   (raxmatRes.data || {}).url
            // }"}]]}`
          );
          withReactContent(Swal).fire({
            position: "top-end",
            icon: "success",
            title: intl.formatMessage({ id: "COMMON.CHEQUE_BILL" }),
            showConfirmButton: false,
            timer: 2000,
          });

          // tg.close();
          localStorage.setItem("cart", JSON.stringify([]));
          navigate(
            `/complete?id=${res.data.order.id}&paymentUrl=${
              (raxmatRes.data || {}).url
            }`
          );
          return;
        }
      }

      // setStep({
      //   id: 4,
      //   title: intl.formatMessage({
      //     id: "COMMON.ORDER_IS_ACCEPTED",
      //   }),
      // });
      localStorage.setItem("cart", JSON.stringify([]));
      navigate(`/complete?id=${res.data.order.id}`);
    } catch (err) {
      console.log("error", err);
      return;
    }
  };

  const mapOnClick = async (e) => {
    let coordsInfo = await e.get("coords");

    setCoords(coordsInfo);
    setDefaultMapLocation({
      latitude: await coordsInfo[0],
      longitude: await coordsInfo[1],
    });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE}/get-address`,
        {
          latitude: coordsInfo[0],
          longitude: coordsInfo[1],
        }
      );

      // setDeliveryPrice(res.data?.deliveryPrice);
      setAddress(res.data?.address);
      setBranch(res.data.branch);
    } catch (err) {
      console.log("error", err);
      return;
    }
  };

  const showOutOfPolygonAlert = async (e) => {
    withReactContent(Swal).fire({
      position: "top-end",
      icon: "error",
      title: intl.formatMessage({ id: "COMMON.OUT_OF_DELIVERY_ZONE" }),
      showConfirmButton: false,
      timer: 1000,
    });
  };

  const MainComponent = () => {
    return (
      <div className="es-placing-order-main">
        <div className="mb-3">
          <label>{intl.formatMessage({ id: "COMMON.REGION" })}</label>
          <select
            className="form-select es-input-grey"
            onChange={(e) => {
              if (e.target.value == 13) {
                setCityId(13);
              } else {
                setCityId(0);
              }

              setAreaId(e.target.value);
            }}
          >
            <option selected>
              {intl.formatMessage({ id: "COMMON.CHOOSE_CITY" })}
            </option>
            {regions
              .filter((x) => x.parent_id == null)
              .map((region) => (
                <option key={"region-" + region.id} value={region.id}>
                  {region.name_ru}
                </option>
              ))}
          </select>
        </div>
        {cityId != 13 && (
          <div className="mb-3">
            <label>{intl.formatMessage({ id: "COMMON.CITY" })}</label>
            <select
              className="form-select es-input-grey"
              onChange={(e) => {
                setCityId(e.target.value);
              }}
            >
              <option selected>
                {intl.formatMessage({ id: "COMMON.CHOOSE_CITY" })}
              </option>
              {regions
                .filter((x) => x.parent_id == areaId)
                .map((region) => (
                  <option key={"region-" + region.id} value={region.id}>
                    {region.name_ru}
                  </option>
                ))}
            </select>
          </div>
        )}

        {/* <div>
          <label>{intl.formatMessage({ id: "COMMON.DISTRICT" })}</label>
          <div className="input-group mb-3">
            <input
              type="text"
              className="es-input-grey form-control"
              placeholder={intl.formatMessage({
                id: "COMMON.DISTRICT",
              })}
              aria-label="name"
              aria-describedby="addon-wrapping"
              onChange={(e) => setDistrict(e.target.value)}
              value={district}
            />
          </div>
        </div> */}
        {cityId == 13 && (
          <div className="mb-3">
            <label>{intl.formatMessage({ id: "COMMON.DISTRICT" })}</label>
            <select
              className="form-select es-input-grey"
              onChange={(e) => setDistrictId(e.target.value)}
            >
              <option selected>
                {intl.formatMessage({ id: "COMMON.CHOOSE_DISTRICT" })}
              </option>
              {districts.map((district) => (
                <option key={"district-" + district.id} value={district.id}>
                  {district.name_ru}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* <div className="mb-3">
          <label>{intl.formatMessage({ id: "COMMON.DELIVERY_ADDRESS" })}</label>
          <button
            type="button"
            className="es-placing-order-address-btn btn"
            onClick={() => {
              setStep({
                id: 3,
                title: intl.formatMessage({ id: "COMMON.DELIVERY_ADDRESS" }),
              });
            }}
          >
            <p>
              {address || intl.formatMessage({ id: "COMMON.CHOOSE_ADDRESS" })}
            </p>{" "}
            <AddSquareIcon />
          </button>
        </div> */}

        <div>
          <label>{intl.formatMessage({ id: "COMMON.ADDRESS" })}</label>
          <div className="input-group mb-3">
            <input
              type="text"
              className="es-input-grey form-control"
              placeholder={intl.formatMessage({
                id: "COMMON.ADDRESS_DESCRIPTION",
              })}
              aria-label="name"
              aria-describedby="addon-wrapping"
              onChange={(e) => setStreet(e.target.value)}
              value={street}
            />
          </div>
        </div>
        <div className="col-12">
          <label>{intl.formatMessage({ id: "COMMON.COMMENT" })}</label>
          <div className="input-group">
            <textarea
              type="text"
              className="es-input-grey form-control"
              placeholder={intl.formatMessage({ id: "COMMON.COMMENT" })}
              aria-label="Комментарий"
              aria-describedby="addon-wrapping"
              onChange={(e) => setComment(e.target.value)}
              value={comment}
            />
          </div>
        </div>
        {/* <hr />
        <div className="es-placing-order-address">
          <h4>{intl.formatMessage({ id: "COMMON.YOUR_ORDER" })}</h4>
          <div className="row">
            <div className="col-6">
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="es-input-grey form-control"
                  placeholder={intl.formatMessage({ id: "COMMON.HOME" })}
                  aria-label="name"
                  aria-describedby="addon-wrapping"
                  onChange={(e) => setHome(e.target.value)}
                  value={home}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="es-input-grey form-control"
                  placeholder={intl.formatMessage({ id: "COMMON.FLOOR" })}
                  aria-label="name"
                  aria-describedby="addon-wrapping"
                  onChange={(e) => setApartment(e.target.value)}
                  value={apartment}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="es-input-grey form-control"
                  placeholder={intl.formatMessage({ id: "COMMON.ENTRANCE" })}
                  aria-label="name"
                  aria-describedby="addon-wrapping"
                  onChange={(e) => setPoint(e.target.value)}
                  value={point}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="es-input-grey form-control"
                  placeholder={intl.formatMessage({ id: "COMMON.HOME_CODE" })}
                  aria-label="name"
                  aria-describedby="addon-wrapping"
                  onChange={(e) => setHomeCode(e.target.value)}
                  value={homeCode}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="input-group">
                <textarea
                  type="text"
                  className="es-input-grey form-control"
                  placeholder={intl.formatMessage({ id: "COMMON.COMMENT" })}
                  aria-label="Комментарий"
                  aria-describedby="addon-wrapping"
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                />
              </div>
            </div>
          </div>
        </div> */}
        <hr />
        <div className="es-placing-order-type-payments">
          <label>{intl.formatMessage({ id: "COMMON.PAYMENT_TYPE" })}</label>
          <div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="paymentRadios"
                id="cashRadio"
                checked={payment == "cash"}
                onChange={(e) => {
                  console.log("asdad", e.target);
                  setPayment("cash");
                }}
              />
              <label className="form-check-label" htmlFor="cashRadio">
                {intl.formatMessage({ id: "COMMON.CASH" })}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="paymentRadios"
                id="rxmtRadio"
                checked={payment == "rhmt"}
                onChange={(e) => {
                  setPayment("rhmt");
                }}
              />
              <label className="form-check-label" htmlFor="rxmtRadio">
                <img className="w-100" src="/rhmt.png" alt="raxmat image" />
              </label>
            </div>
          </div>
          {/* <div className="es-placing-order-btns">
            <button
              onClick={() => setPayment("cash")}
              className={clsx(
                "btn",
                { "es-btn-main": payment == "cash" },
                {
                  "es-btn-secondary": payment != "cash",
                }
              )}
            >
              {intl.formatMessage({ id: "COMMON.CASH" })}
            </button>
            <button
              onClick={() => setPayment("raxmat")}
              className={clsx(
                "btn",
                { "es-btn-secondary": payment != "raxmat" },
                {
                  "es-btn-main": payment == "raxmat",
                }
              )}
            >
              Raxmat
            </button>
          </div> */}
        </div>
        <div className="es-window-button">
          <div className="container">
            <div className="es-basket-delivery">
              <h4>{intl.formatMessage({ id: "COMMON.ORDER_PRICE" })}</h4>
              <h4>
                {convertToPrice(getTotalPrice() || 0)}{" "}
                {intl.formatMessage({ id: "COMMON.SUM" })}
              </h4>
            </div>
            {deliveryPrice != null && (
              <div className="es-basket-delivery">
                <h4> {intl.formatMessage({ id: "COMMON.COST_DELIVERY" })}</h4>
                <h4>
                  {convertToPrice(deliveryPrice)}{" "}
                  {intl.formatMessage({ id: "COMMON.SUM" })}
                </h4>
              </div>
            )}

            <div className="es-basket-delivery">
              <h4>{intl.formatMessage({ id: "COMMON.TOTAL_PAYABLE" })}</h4>
              <h4>
                {convertToPrice(getTotalPrice() + deliveryPrice || 0)}{" "}
                {intl.formatMessage({ id: "COMMON.SUM" })}
              </h4>
            </div>

            <button
              className="es-btn-main btn w-100 justify-content-center"
              type="button"
              onClick={createOrder}
              disabled={
                !areaId ||
                !cityId ||
                (cityId == 13 && !districtId) ||
                isLoadingDelPrice
              }
            >
              {isLoadingDelPrice &&
                intl.formatMessage({ id: "COMMON.IN_PROGRESS" })}
              {!isLoadingDelPrice &&
                intl.formatMessage({ id: "COMMON.CONFIRM" })}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const AddressComponent = () => {
    return (
      <div className="es-placing-order-main">
        <form>
          <div>
            <h4>{intl.formatMessage({ id: "COMMON.DELIVERY_ADDRESS" })}</h4>
            <button
              className="es-placing-order-address-btn btn"
              onClick={() =>
                setStep({
                  id: 3,
                  title: intl.formatMessage({ id: "COMMON.DELIVERY_ADDRESS" }),
                })
              }
            >
              <p>
                {address || intl.formatMessage({ id: "COMMON.CHOOSE_ADDRESS" })}
              </p>
              <AddSquareIcon />
            </button>
          </div>
          <hr />
          <div className="es-placing-order-address">
            <h4>{intl.formatMessage({ id: "COMMON.YOUR_ADDRESSES" })}</h4>
            {/* <div className="es-placing-order-address-item">
              <div className="es-form-check form-check" key="private-house">
                <input
                  className="es-form-check-input form-check-input"
                  type="checkbox"
                  value=""
                  id="private-house"
                />
              </div>
              <div>
                <h5>Метро Сергели-1, дом-28</h5>
                <p>Ташкент</p>
              </div>
              <button className="es-address-remove-btn btn">
                <CloseSquareIcon />
              </button>
            </div> */}
          </div>

          <div className="es-window-button">
            <div className="container">
              <button
                className="es-btn-main btn"
                type="button"
                onClick={() =>
                  setStep({
                    id: 1,
                    title: intl.formatMessage({
                      id: "COMMON.PLACING_AN_ORDER",
                    }),
                  })
                }
              >
                {intl.formatMessage({ id: "COMMON.DONE" })}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  };

  const MapComponent = () => {
    return (
      <div className="d-flex flex-column flex-column-fluid h-100">
        {/* <form type="submit" className="input-group flex-nowrap my-2">
          <div className="es-search-input input-group flex-nowrap">
            <input
              type="text"
              className="es-input form-control"
              placeholder={intl.formatMessage({
                id: "COMMON.DELIVERY_ADDRESS",
              })}
              aria-label="Username"
              aria-describedby="addon-wrapping"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
        </form> */}
        <div
          className="es-mapouter flex-column-fluid"
          style={{ paddingBottom: "3rem" }}
        >
          <YMaps
            query={{
              apikey: process.env.REACT_APP_YMAPS_KEY,
              lang: "ru_RU",
              load: "package.full",
            }}
          >
            <Map
              onClick={showOutOfPolygonAlert}
              defaultState={{
                center: [
                  defaultMapLocation.latitude,
                  defaultMapLocation.longitude,
                ],
                zoom: 12,
                controls: [],
              }}
              options={{
                suppressMapOpenBlock: true,
              }}
              style={{ width: "100%", height: "100%" }}
            >
              <FullscreenControl />
              <SearchControl
                options={{
                  float: "left",
                }}
              />
              <ZoomControl />
              <Placemark
                geometry={coords}
                options={{
                  preset: "islands#icon",
                }}
              />

              <Polygon
                onClick={mapOnClick}
                geometry={[polygonJson.map((x) => [x[1], x[0]])]}
                options={{
                  fillColor: "#00a0e3",
                  strokeColor: "#3e819d",
                  opacity: 0.3,
                  strokeWidth: 5,
                }}
              />
            </Map>
          </YMaps>
        </div>
        <div className="es-window-button">
          <div className="container">
            <button
              className="es-btn-main btn w-100 justify-content-center"
              type="button"
              onClick={() =>
                setStep({
                  id: 1,
                  title: intl.formatMessage({
                    id: "COMMON.PLACING_AN_ORDER",
                  }),
                })
              }
            >
              {intl.formatMessage({
                id: "COMMON.DONE",
              })}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const NotifactionComponent = () => {
    return (
      <div className="d-flex flex-column flex-column-fluid h-100">
        <div className="es-notifation">
          <TickSquareIcon />
          <p>
            {intl.formatMessage({
              id: "COMMON.ACCEPTED_TEXT",
            })}
          </p>
          <h5>
            {intl.formatMessage({
              id: "COMMON.INFO_ABOUT_ORDER",
            })}
            :
          </h5>
          <div className="es-item d-flex">
            <p>
              {intl.formatMessage({
                id: "COMMON.ORDER_NUMBER",
              })}
              :
            </p>
            <h3>#{order.id}</h3>
          </div>
          <div className="es-item d-flex">
            <p>
              {intl.formatMessage({
                id: "COMMON.ORDER_DATE",
              })}
              :
            </p>
            <h3>{moment(order.date).format("DD-MM-YYYY")}</h3>
          </div>
        </div>
        <div className="es-window-button">
          <div className="container">
            <button
              className="es-btn-main btn"
              type="button"
              onClick={() => navigate("/")}
            >
              {intl.formatMessage({
                id: "COMMON.TO_THE_MAIN_PAGE",
              })}
            </button>
          </div>
        </div>
      </div>
    );
  };

  if (loading) return <Loader />;

  return (
    <div className="es-placing-order">
      <div
        className="container"
        style={{ height: step.id == 3 ? "90vh" : "none" }}
      >
        <HeaderTitle title={step.title} link={true} basket={false} />

        {step.id == 1 && MainComponent()}
        {step.id == 2 && AddressComponent()}
        {step.id == 3 && MapComponent()}
        {step.id == 4 && NotifactionComponent()}
      </div>
    </div>
  );
}

export default PlacingOrder;
