import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";

function PublicRoute() {
  const location = useLocation();
  const fromPage = location.state?.from?.pathname || "/";
  const [user, setUser] = useLocalStorage("user", {});

  if (!!user?.id) {
    return <Navigate to={fromPage} replace />;
  }

  return <Outlet />;
}

export default PublicRoute;
