import axios from "axios";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { setCartReducer } from "../../stores/baseSlice";
import { useLocalStorage } from "../../hooks/useLocalStorage";

// COMPONENTS
import { HeaderTitle, Loader } from "../../components";
import {
  convertToPrice,
  getMediaPATH,
  getProductName,
} from "../../helpers/general";

// ICONS
import { ReactComponent as CloseSquareIcon } from "../../assets/icons/close-square.svg";

function Basket() {
  const intl = useIntl();

  const [cart, setCart] = useLocalStorage("cart", []);
  const [products, setProducts] = useState([]);
  let [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!cart.length) {
      setProducts([]);
      setLoading(false);
      return;
    }

    axios
      .post(`${process.env.REACT_APP_API_BASE}/get-products/by-id`, {
        ids: cart.map((x) => x.id),
      })
      .then((res) => {
        let products = res.data.products;

        for (let product of products) {
          let cartItem = cart.find((x) => x.id == product.id);
          product.q = cartItem.q;
        }
        setProducts(products);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [cart]);

  const getTotalPrice = () => {
    return products.reduce((acc, curr) => acc + curr.q * curr.current_price, 0);
  };
  // CART METHODS
  const incrQ = (id) => {
    let cartIdx = cart.findIndex((x) => x.id == id);
    if (cartIdx < 0) return;
    let newCart = JSON.parse(JSON.stringify(cart || []));

    let product = products.find((x) => x.id == id);

    if (!product || (product.quantity || 1) < newCart[cartIdx].q + 1) {
      console.log("insiiide");
      withReactContent(Swal).fire({
        position: "top-end",
        icon: "error",
        title: intl.formatMessage({ id: "COMMON.PRODUCT_QUANTITY_OVER" }),
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    newCart[cartIdx].q++;
    setCart(newCart);
  };
  const decrQ = (id) => {
    let cartIdx = cart.findIndex((x) => x.id == id);
    if (cartIdx < 0) return;

    let newCart = JSON.parse(JSON.stringify(cart || []));

    if (newCart[cartIdx].q == 1) {
      newCart.splice(cartIdx, 1);
    } else {
      newCart[cartIdx].q--;
    }

    setCart(newCart);
  };
  const rmvI = (id) => {
    let cartIdx = cart.findIndex((x) => x.id == id);
    if (cartIdx < 0) return;

    let newCart = JSON.parse(JSON.stringify(cart || []));
    newCart.splice(cartIdx, 1);

    dispatch(setCartReducer(newCart));
    return setCart(newCart);
  };
  // EMD OF CART METHODS

  const AuthorizationCheck = () => {
    navigate("/placing-order");
  };

  if (loading) return <Loader />;

  return (
    <div className="es-basket">
      <div className="container">
        <HeaderTitle
          title={intl.formatMessage({ id: "COMMON.BASKET" })}
          link={true}
          linkUrl="/"
          basket={false}
        />
        {products.length > 0 ? (
          <>
            <div className="es-basket-cards">
              {products.map((product, i) => (
                <>
                  <div
                    className="es-basket-card"
                    key={"product-" + product.id + "-" + i}
                  >
                    <div className="es-basket-card-info">
                      <img
                        // src={getMediaPATH("products", product.image)}
                        src={product.url}
                        alt=""
                      />
                      <h4 className="es-basket-card-title text-capitalize">
                        {getProductName(product)}
                      </h4>
                      <button className="es-basket-remove-btn btn">
                        <CloseSquareIcon onClick={(e) => rmvI(product.id)} />
                      </button>
                    </div>
                    <div className="es-basket-card-price">
                      <div className="es-basket-card-item">
                        <p>{intl.formatMessage({ id: "COMMON.PRICE" })}</p>
                        <div>
                          <div
                            className={`es-basket-card-price ${
                              product.discount_percent > 0 && "active"
                            }`}
                          >
                            {convertToPrice(Math.round(product.current_price))}{" "}
                            {intl.formatMessage({ id: "COMMON.SUM" })}
                          </div>
                          {product.discount_percent > 0 && (
                            <p className="es-basket-card-sale-price">
                              <span>
                                {" "}
                                {convertToPrice(
                                  Math.round(product.retail_price)
                                )}
                              </span>
                              {intl.formatMessage({ id: "COMMON.SUM" })}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="es-basket-card-item">
                        <p>{intl.formatMessage({ id: "COMMON.AMOUNT" })}</p>
                        <div className="es-amounts">
                          <div
                            className="es-amounts-left es-amounts-box"
                            onClick={(e) => decrQ(product.id)}
                          >
                            -
                          </div>
                          <div className="es-amounts-middle es-amounts-box">
                            {product.q}
                          </div>
                          <div
                            className="es-amounts-right es-amounts-box"
                            onClick={(e) => incrQ(product.id)}
                          >
                            +
                          </div>
                        </div>
                      </div>
                      <div className="es-basket-card-item">
                        <p>{intl.formatMessage({ id: "COMMON.TOTAL" })}</p>
                        <div className="es-basket-card-total">
                          {convertToPrice(
                            Math.round(product.current_price * product.q)
                          )}{" "}
                          {intl.formatMessage({ id: "COMMON.SUM" })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                </>
              ))}
            </div>
            <div className="es-window-button">
              <div className="container">
                {/* <div className="es-basket-total">
                  <h3>{intl.formatMessage({ id: "COMMON.TOTAL" })}</h3>
                  <h3>
                    {convertToPrice(getTotalPrice())}{" "}
                    {intl.formatMessage({ id: "COMMON.SUM" })}
                  </h3>
                </div> */}
                <button
                  className="es-btn-main btn w-100 justify-content-between"
                  type="button"
                  onClick={AuthorizationCheck}
                >
                  {intl.formatMessage({ id: "COMMON.PLACE_AN_ORDER" })}
                  <span> {convertToPrice(getTotalPrice())}</span>
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="es-basket-notification">
            <h4>{intl.formatMessage({ id: "COMMON.BASKET_EMPTY" })}</h4>
            <p>{intl.formatMessage({ id: "COMMON.USE_SEARCH" })}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Basket;
