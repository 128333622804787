import axios from "axios";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Card, Header, Loader } from "../../components";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { convertToPrice, getMediaUrl, getName } from "../../helpers/general";
import { useLocalStorage } from "../../hooks/useLocalStorage";

import { useIntl } from "react-intl";

import { setLanguage, useLang } from "../../i18n/Metronici18n";

function Home() {
  const intl = useIntl();
  const locale = useLang();

  const [children, setChildren] = useState([]);
  const [showSecond, setShowSecond] = useState(false);

  const [cart, setCart] = useLocalStorage("cart", []);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const bestsellerProducts = useSelector(
    (state) => state.base.bestsellerProducts
  );

  const banners = useSelector((state) => state.base.banners);
  const rootCategories = useSelector((state) => state.base.rootCategories);

  const getTotalPrice = () => {
    return products.reduce((acc, curr) => acc + curr.q * curr.current_price, 0);
  };

  useEffect(() => {
    if (!cart.length) {
      setProducts([]);
      setLoading(false);
      return;
    }

    axios
      .post(`${process.env.REACT_APP_API_BASE}/get-products/by-id`, {
        ids: cart.map((x) => x.id),
      })
      .then((res) => {
        let products = res.data.products;

        for (let product of products) {
          let cartItem = cart.find((x) => x.id == product.id);
          product.q = cartItem.q;
        }
        setProducts(products);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [cart]);

  if (loading) return <Loader />;

  return (
    <div className="es-home">
      <Header
        children={children}
        setChildren={setChildren}
        showSecond={showSecond}
        setShowSecond={setShowSecond}
      />
      <section className="es-categories-main" style={{ paddingTop: 10 }}>
        <div className="container">
          <Swiper className="es-slider">
            <SwiperSlide>
              {!!banners.find((x) => x.type == 1) && (
                <img
                  src={getMediaUrl(
                    "banners",
                    banners.find((x) => x.type == 1)["file_" + locale]
                  )}
                  alt="slider image"
                />
              )}
            </SwiperSlide>
          </Swiper>

          <div className="es-categories" style={{ gridColumn: 4, gap: 3 }}>
            <div className="grid">
              {rootCategories?.map((category, index) => (
                <div className="es-category">
                  <div
                    className="es-square"
                    key={"cateogry-" + index}
                    onClick={() => {
                      setChildren(category.children);
                      setShowSecond(true);
                    }}
                  >
                    <img src={getMediaUrl("category", category.image)} alt="" />
                  </div>
                  <p>
                    {getName(
                      category,
                      intl.formatMessage({ id: "COMMON.LOCAL_CATEGORY" })
                    )}
                  </p>
                </div>
              ))}
            </div>

            <div className="col-12">
              <Link to={"/products?categoryId=0&sort=discount"}>
                {!!banners.find((x) => x.type == 3) && (
                  <img
                    className="es-categories-banner"
                    src={getMediaUrl(
                      "banners",
                      banners.find((x) => x.type == 3).file_ru
                    )}
                    alt="sale banner image"
                  />
                )}
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="es-products-main">
        <div className="container">
          <h2>{intl.formatMessage({ id: "COMMON.BESTSELLERS" })}</h2>
          <div className="row">
            {bestsellerProducts.map((product) => (
              <div className="col-6" key={"bestsellerProd-" + product.id}>
                <Card product={product} />
              </div>
            ))}
          </div>
        </div>
      </section>

      <div className="es-window-button">
        <div className="container">
          <Link to="/basket" className="es-btn-main btn">
            <label>{intl.formatMessage({ id: "COMMON.BASKET" })}</label>
            <span>{products ? convertToPrice(getTotalPrice()) : 0}</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Home;
