import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { ReactComponent as NotFoundIcon } from "../../assets/icons/404.svg";

function PageNotFound() {
  const intl = useIntl();

  return (
    <div className="container">
      <div className="es-not-found">
        <h3>{intl.formatMessage({ id: "COMMON.PAGE_NOT_FOUND" })}</h3>
        <NotFoundIcon />
        <Link to="/">
          <button className="btn" type="button">
            {intl.formatMessage({ id: "COMMON.TO_THE_MAIN_PAGE" })}
          </button>
        </Link>
      </div>
    </div>
  );
}

export default PageNotFound;
