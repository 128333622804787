import React from "react";
import { RotatingSquare } from "react-loader-spinner";

function Loader() {
  return (
    <div className="es-loader">
      <RotatingSquare
        visible={true}
        height="100"
        width="100"
        color="#000"
        ariaLabel="rotating-square-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
}

export default Loader;
