import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { ReactComponent as BasketIcon } from "../../assets/icons/basket.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow-right.svg";

function HeaderTitle({
  title,
  basket = true,
  link = false,
  linkUrl = "",
  offcanvas = false,
  onCloseOffCanvas,
  fixed = false,
}) {
  const cartSelector = useSelector((state) => state.base.cart);
  return (
    <div className={clsx("es-header-title", { "cs-fixed": fixed == true })}>
      {link && (
        <Link to={linkUrl.length > 0 ? linkUrl : -1} className="es-back-btn">
          <ArrowRightIcon />
        </Link>
      )}
      {offcanvas && (
        <div
          className="es-back-btn"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={onCloseOffCanvas}
        >
          <ArrowRightIcon />
        </div>
      )}

      <p className="text-capitalize">{title}</p>

      {basket ? (
        <Link to="/basket" className="es-back-btn">
          <BasketIcon />
          <span className="es-header-cart-badge badge">
            {cartSelector.length}
          </span>
        </Link>
      ) : null}
    </div>
  );
}

export default HeaderTitle;
