import axios from "axios";
import { useIntl } from "react-intl";
import React, { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import { Card, HeaderTitle, Loader } from "../../components";

import { ReactComponent as ArrowDownIcon } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as CandleIcon } from "../../assets/icons/candle.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-3.svg";
import { sortSizes } from "../../helpers/general";

let sortList = [
  {
    id: "discount",
    title: "Акционные",
    field: "discount",
  },
  {
    id: "new",
    title: "Новинки",
  },
  {
    id: "price-desc",
    title: "По убыванию цены",
  },
  {
    id: "price-asc",
    title: "По возрастанию цены",
  },
];

function Products() {
  const location = useLocation();
  const intl = useIntl();
  // let { id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();

  // FILTERS
  let page = parseInt(searchParams.get("page"), 10) || 0;
  let pageSize = parseInt(searchParams.get("pageSize"), 10) || 10;
  let categoryId = searchParams.get("categoryId") || "";

  let sort = searchParams.get("sort") || "";
  let typesFilter = searchParams.get("typesFilter") || "";
  let sizesFilter = searchParams.get("sizesFilter") || "";
  let collectionsFilter = searchParams.get("collectionsFilter") || "";

  let [products, setProducts] = useState([]);
  let [sizes, setSizes] = useState([]);
  let [collections, setCollections] = useState([]);
  let [types, setTypes] = useState([]);
  const [paginationData, setPaginationData] = React.useState({
    count: 1,
    page: 0,
    pageSize: 10,
    totalPageCount: 1,
  });

  let [loading, setLoading] = useState([]);

  const getProducts = () => {
    let newPaage = page + 1;

    let typesArr = typesFilter.split("%%").filter((x) => !!x.length);
    let sizesArr = sizesFilter.split("%%").filter((x) => !!x.length);
    let collectionsArr = collectionsFilter
      .split("%%")
      .filter((x) => !!x.length);

    axios
      .post(`${process.env.REACT_APP_API_BASE}/get-products`, {
        cats: [categoryId],
        page: newPaage,
        pageSize,
        sort,
        types: typesArr,
        sizes: sizesArr,
        collections: collectionsArr,
      })
      .then((res) => {
        setProducts((prev) => [...prev, ...res.data.products]);
        setSizes(
          sortSizes((res.data.sizes || []).map((x) => ({ size: x }))).map(
            (x) => x.size
          )
        );
        setCollections((res.data.collections || []).sort());
        setTypes((res.data.types || []).sort());

        setSearchParams({
          ...getQueryObject(),
          page: res.data.pagination.page,
          categoryId: categoryId,
        });
        setPaginationData({
          count: res.data.pagination.count,
          page: res.data.pagination.page,
          pageSize: res.data.pagination.pageSize,
          totalPageCount: Math.ceil(
            res.data.pagination.count / res.data.pagination.pageSize
          ),
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const refreshData = () => {
    getProducts();
  };

  useEffect(() => {
    getProducts();
  }, [categoryId, sort, typesFilter, sizesFilter, collectionsFilter]);

  const getQueryObject = () => {
    let obj = {};

    if (!!categoryId) obj.categoryId = categoryId;
    if (!!page) obj.page = page;
    if (!!pageSize) obj.pageSize = pageSize;
    if (!!sort) obj.sort = sort;
    if (!!typesFilter) obj.typesFilter = typesFilter;
    if (!!sizesFilter) obj.sizesFilter = sizesFilter;
    if (!!collectionsFilter) obj.collectionsFilter = collectionsFilter;

    return obj;
  };
  // const onMoreClick = (e) => {
  //   setSearchParams({ ...getQueryObject(), page: page + 1 });

  //   axios
  //     .post(`${process.env.REACT_APP_API_BASE}/get-products`, {
  //       cats: [id],
  //       page: page + 1,
  //       pageSize,
  //       sort,
  //     })
  //     .then((res) => {
  //       let nextProducts = products.map((x) => x);
  //       nextProducts.push(...res.data.products);
  //       setProducts(nextProducts);

  //       setPaginationData({
  //         count: res.data.pagination.count,
  //         page: res.data.pagination.page,
  //         pageSize: res.data.pagination.pageSize,
  //         totalPageCount: Math.ceil(
  //           res.data.pagination.count / res.data.pagination.pageSize
  //         ),
  //       });
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };
  const onSortClick = (key) => {
    if (key == sort) key = "";
    setProducts([]);
    setSearchParams({ ...getQueryObject(), page: 0, sort: key });
  };
  const onSortConfirm = () => {};
  const onSortReset = () => {
    setSearchParams({ ...getQueryObject(), page: 0, sort: "" });
  };

  const filterChecked = (selector = "", value = "") => {
    if (!selector) return false;

    let filtersArr = [];
    if (selector == "type") filtersArr = typesFilter;
    else if (selector == "size") filtersArr = sizesFilter;
    else if (selector == "collection") filtersArr = collectionsFilter;
    else return false;

    filtersArr = filtersArr.split("%%").filter((x) => x.length > 0);

    return filtersArr.includes(value);
  };
  const resetFilters = () => {
    setSearchParams({
      ...getQueryObject(),
      page: 0,
      typesFilter: "",
      sizesFilter: "",
      collectionsFilter: "",
    });
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="es-products">
        <div className="container">
          <HeaderTitle
            title={intl.formatMessage({ id: "COMMON.PRODUCTS" })}
            link={true}
            linkUrl="/"
          />
          <div className="row mt-3">
            <div className="col-6">
              <button
                className="btn es-btn-secondary w-100 py-3"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasFilter"
                aria-controls="offcanvasFilter"
                aria-label="Toggle navigation"
                type="button"
              >
                {intl.formatMessage({ id: "COMMON.FILTER" })}
                <CandleIcon className="ms-2" />
              </button>
            </div>
            <div className="col-6">
              <button
                className="btn es-btn-secondary w-100 py-3"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasSort"
                aria-controls="offcanvasSort"
                aria-label="Toggle navigation"
              >
                {intl.formatMessage({ id: "COMMON.SORTING" })}
                <ArrowIcon className="ms-2" />
              </button>
            </div>
          </div>
          <section className="es-products-main">
            <InfiniteScroll
              dataLength={products.length}
              next={getProducts}
              hasMore={true}
              refreshFunction={getProducts}
              pullDownToRefresh
              pullDownToRefreshThreshold={50}
              style={{
                overflowX: "hidden",
              }}
            >
              <div className="row">
                {products.map((product) => (
                  <div className="col-6" key={"product-" + product.id}>
                    <Card product={product} state={location.search} />
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          </section>
        </div>
      </div>
      <div
        className="es-offcanvas-sort offcanvas offcanvas-end"
        tabIndex={-1}
        id="offcanvasSort"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div className="container">
          <HeaderTitle
            title={intl.formatMessage({ id: "COMMON.SORTING" })}
            offcanvas={true}
          />
          <div className="es-offcanvas-body offcanvas-body">
            <h4> {intl.formatMessage({ id: "COMMON.SORTING_BY" })}</h4>
            <div className="es-offcanvas-list">
              {sortList.map((sl) => (
                <div
                  className="es-form-check form-check"
                  key={"sort-" + sl.id}
                  onClick={(_) => onSortClick(sl.id)}
                >
                  <input
                    className="es-form-check-input form-check-input"
                    type="checkbox"
                    checked={sl.id == sort}
                  />
                  <label className="es-form-check-label form-check-label">
                    {sl.title}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="es-window-button">
            <div className="container">
              <div className="d-flex">
                <button
                  className="es-btn-main btn me-1 w-100 justify-content-center"
                  type="button"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  onClick={() => {
                    onSortConfirm();
                    setLoading(true);
                    refreshData();
                  }}
                >
                  {intl.formatMessage({ id: "COMMON.APPLY" })}
                </button>
                <button
                  className="es-btn-secondary ms-1 btn w-100"
                  type="button"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  onClick={onSortReset}
                >
                  {intl.formatMessage({ id: "COMMON.RESET" })}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="es-offcanvas-filter offcanvas offcanvas-end"
        tabIndex={-1}
        id="offcanvasFilter"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div className="container">
          <HeaderTitle
            title={intl.formatMessage({ id: "COMMON.FILTER" })}
            offcanvas={true}
          />

          <div className="es-offcanvas-body offcanvas-body">
            <div className="es-offcanvas-type">
              <h4>{intl.formatMessage({ id: "COMMON.TYPE_PRODUCT" })}</h4>
              <div className="es-offcanvas-list">
                {types.map((typeF, i) => (
                  <div
                    className="es-form-check form-check"
                    key={"typeF-" + i + typesFilter}
                  >
                    <input
                      className="es-form-check-input form-check-input"
                      type="checkbox"
                      value={typeF}
                      id={typeF}
                      checked={filterChecked("type", typeF)}
                      onChange={(e) => {
                        e.preventDefault();

                        let filtersArr = typesFilter
                          .split("%%")
                          .filter((x) => !!x.length);
                        if (filtersArr.includes(e.target.value)) {
                          filtersArr = filtersArr.filter(
                            (item) => item !== e.target.value
                          );
                        } else {
                          filtersArr = [...filtersArr, e.target.value];
                        }

                        setProducts([]);
                        setSearchParams({
                          ...getQueryObject(),
                          page: 0,
                          typesFilter: filtersArr.join("%%"),
                        });
                      }}
                    />
                    <label
                      className="es-form-check-label form-check-label"
                      htmlFor={typeF}
                    >
                      {typeF}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <hr />
            <div className="es-offcanvas-type">
              <h4>{intl.formatMessage({ id: "COMMON.SIZE" })}</h4>
              <div className="es-offcanvas-list row">
                {sizes.map((sizeF, i) => (
                  <div className="col-4" key={"sizeF-" + i + sizesFilter}>
                    <div className="es-form-check form-check">
                      <input
                        className="es-form-check-input form-check-input"
                        type="checkbox"
                        value={sizeF}
                        id={sizeF}
                        checked={filterChecked("size", sizeF)}
                        onChange={(e) => {
                          e.preventDefault();

                          let filtersArr = sizesFilter
                            .split("%%")
                            .filter((x) => !!x.length);
                          if (filtersArr.includes(e.target.value)) {
                            filtersArr = filtersArr.filter(
                              (item) => item !== e.target.value
                            );
                          } else {
                            filtersArr = [...filtersArr, e.target.value];
                          }

                          setProducts([]);
                          setSearchParams({
                            ...getQueryObject(),
                            page: 0,
                            sizesFilter: filtersArr.join("%%"),
                          });
                        }}
                      />
                      <label
                        className="es-form-check-label form-check-label"
                        htmlFor={sizeF}
                      >
                        {sizeF}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <hr />
            <div className="es-offcanvas-type">
              <h4>{intl.formatMessage({ id: "COMMON.COLLECTIONS" })}</h4>
              <div className="es-offcanvas-list">
                {collections.map((collectionF, i) => (
                  <div
                    className="es-form-check form-check"
                    key={"collectionF-" + i + collectionsFilter}
                  >
                    <input
                      className="es-form-check-input form-check-input"
                      type="checkbox"
                      value={collectionF}
                      id={collectionF}
                      checked={filterChecked("collection", collectionF)}
                      onChange={(e) => {
                        e.preventDefault();

                        let filtersArr = collectionsFilter
                          .split("%%")
                          .filter((x) => !!x.length);
                        if (filtersArr.includes(e.target.value)) {
                          filtersArr = filtersArr.filter(
                            (item) => item !== e.target.value
                          );
                        } else {
                          filtersArr = [...filtersArr, e.target.value];
                        }

                        setProducts([]);
                        setSearchParams({
                          ...getQueryObject(),
                          page: 0,
                          collectionsFilter: filtersArr.join("%%"),
                        });
                      }}
                    />
                    <label
                      className="es-form-check-label form-check-label"
                      htmlFor={collectionF}
                    >
                      {collectionF}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="es-window-button">
              <div className="container">
                <div className="d-flex">
                  <button
                    className="es-btn-main btn me-1 w-100 justify-content-center"
                    type="button"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={() => {
                      setLoading(true);
                      refreshData();
                    }}
                  >
                    {intl.formatMessage({ id: "COMMON.APPLY" })}
                  </button>
                  <button
                    className="es-btn-secondary btn w-100"
                    type="button"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={(e) => {
                      resetFilters();
                    }}
                  >
                    {intl.formatMessage({ id: "COMMON.RESET" })}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Products;
