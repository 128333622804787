import { useIntl } from "react-intl";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Offcanvas from "react-bootstrap/Offcanvas";

import { getName } from "../../helpers/general";

import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg";
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as SearchNormalIcon } from "../../assets/icons/search-normal.svg";
import { HeaderTitle } from "..";

import { ReactComponent as InfoCircleIcon } from "../../assets/icons/info-circle.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/profile.svg";
import { ReactComponent as MessagesIcon } from "../../assets/icons/messages.svg";
import { ReactComponent as GlobalIcon } from "../../assets/icons/global.svg";

function Header({ showSecond, setShowSecond, children, setChildren }) {
  const intl = useIntl();
  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  const rootCategories = useSelector((state) => state.base.rootCategories);
  const [q, setQ] = useState("");

  let infos = [
    {
      icon: <ProfileIcon />,
      active: false,
      url: "profile",
      title: intl.formatMessage({ id: "COMMON.PROFILE" }),
    },
    {
      icon: <GlobalIcon />,
      active: false,
      url: "language",
      title: intl.formatMessage({ id: "COMMON.LANGUAGE" }),
    },
    {
      icon: <InfoCircleIcon />,
      active: false,
      url: "shipping-payment",
      title: intl.formatMessage({ id: "COMMON.SHIPPING_AND_PAYMENT" }),
    },
  ];

  const onSearchFormSubmit = (e) => {
    e.preventDefault();

    navigate(`/search?q=${q}`);
  };

  return (
    <>
      <nav className="es-navbar navbar navbar-dark">
        <div className="container-fluid">
          <div className="d-flex w-100">
            <button
              className="navbar-toggler es-navbar-toggler"
              type="button"
              onClick={() => setShow(true)}
            >
              <MenuIcon />
            </button>
            <form
              type="submit"
              className="input-group flex-nowrap"
              onSubmit={onSearchFormSubmit}
            >
              <div
                className="es-search-input input-group flex-nowrap"
                onClick={() => {
                  return navigate(`/search`);
                }}
              >
                <input
                  type="text"
                  className="es-input form-control"
                  placeholder={intl.formatMessage({ id: "COMMON.SEARCH" })}
                  aria-label="Username"
                  aria-describedby="addon-wrapping"
                  onKeyUp={(e) => setQ(e.target.value)}
                />
                <button className="btn es-input-text input-group-text">
                  <span id="addon-wrapping">
                    <SearchNormalIcon />
                  </span>
                </button>
              </div>
            </form>
          </div>

          <Offcanvas
            show={show}
            onHide={() => setShow(false)}
            className={"es-header-offcanvas"}
            placement={"end"}
          >
            <div className="container">
              <HeaderTitle
                title={intl.formatMessage({ id: "COMMON.MENU" })}
                offcanvas={true}
                onCloseOffCanvas={() => {
                  setShow(false);
                }}
              />
            </div>
            <div className="es-offcanvas-body offcanvas-body">
              <ul className="es-offcanvas-categories">
                {rootCategories.map((category) => (
                  <div
                    key={"rootCat-" + category.id}
                    onClick={() => {
                      setChildren(category.children);
                      setShowSecond(true);
                    }}
                  >
                    <li>
                      <p>
                        {getName(
                          category,
                          intl.formatMessage({ id: "COMMON.LOCAL_CATEGORY" })
                        )}
                      </p>
                      <ArrowDownIcon />
                    </li>
                  </div>
                ))}
                <Link to={"/products?categoryId=0&sort=discount"}>
                  <li>
                    <p className={"active"}>
                      {intl.formatMessage({ id: "COMMON.SALES" })}
                    </p>
                    <ArrowDownIcon />
                  </li>
                </Link>
              </ul>
              <ul className="es-offcanvas-info">
                {infos.map((info, i) => (
                  <Link
                    to={`/${info.url}`}
                    key={"hInfo-" + i}
                    onClick={() => {
                      setShow(false);
                      setShowSecond(true);
                    }}
                  >
                    <li>
                      <p>
                        {info.icon} {info.title}
                      </p>
                      <ArrowDownIcon />
                    </li>
                  </Link>
                ))}
                <a href={`https://t.me/NIKEUZBN1`} target="_blank">
                  <li>
                    <p>
                      {<MessagesIcon />}{" "}
                      {intl.formatMessage({
                        id: "COMMON.ONLINE_CONSULTATION",
                      })}
                    </p>
                    <ArrowDownIcon />
                  </li>
                </a>
              </ul>
            </div>
          </Offcanvas>
          <Offcanvas
            show={showSecond}
            onHide={() => {
              setShow(true);
              setShowSecond(false);
            }}
            className={"es-header-offcanvas"}
            placement={"end"}
          >
            <div className="container">
              <HeaderTitle
                title={intl.formatMessage({ id: "COMMON.MENU" })}
                offcanvas={true}
                onCloseOffCanvas={() => {
                  setShow(true);
                  setShowSecond(false);
                }}
              />
            </div>
            <div className="es-offcanvas-body offcanvas-body">
              <ul className="es-offcanvas-categories">
                {children?.map((child, index) => (
                  <Link
                    to={`products?categoryId=${child.id}`}
                    key={"child-" + child.id}
                    onClick={() => setShowSecond(false)}
                  >
                    <li>
                      <p>
                        {getName(
                          child,
                          intl.formatMessage({ id: "COMMON.LOCAL_CATEGORY" })
                        )}
                      </p>
                      <ArrowDownIcon />
                    </li>
                  </Link>
                ))}
              </ul>
            </div>
          </Offcanvas>
        </div>
      </nav>
    </>
  );
}

export default Header;
