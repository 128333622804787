import "./App.css";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import RouterMain from "./routers";
import { setBaseData, setCartReducer } from "./stores/baseSlice";
import axios from "axios";
import ScrollToTop from "./routers/ScrollToTop";
import { useLocalStorage } from "./hooks/useLocalStorage";
import { useSearchParams } from "react-router-dom";
import { setLanguage, useLang } from "./i18n/Metronici18n";

const tg = window.Telegram.WebApp;

let tgConverted = JSON.parse(
  '{"' +
    (((window.Telegram || {}).WebApp || {}).initData || "")
      .replace(/&/g, '","')
      .replace(/=/g, '":"') +
    '"}',
  function (key, value) {
    return key === "" ? value : decodeURIComponent(value);
  }
);
// let tgConverted = {};

function App() {
  const dispatch = useDispatch();
  const [cart, setCart] = useLocalStorage("cart", []);
  const [user, setUser] = useLocalStorage("user", {});
  let [searchParams, setSearchParams] = useSearchParams();

  let profileId = parseInt(searchParams.get("profileId"), 10) || 0;
  let lang = searchParams.get("lang") || "";

  const userLang = useLang();

  useEffect(() => {
    if (!lang) return;
    if (userLang != lang) setLanguage(lang);
  }, [lang]);
  // useEffect(() => {
  //   if (!user.id) return;

  //   const getMe = async (profileId) => {
  //     try {
  //       const res = await axios.post(
  //         `${process.env.REACT_APP_API_BASE}/get-me`,
  //         {
  //           id: profileId,
  //         }
  //       );

  //       if (res.data.isRegistered) {
  //         localStorage.setItem("user", JSON.stringify(res.data.account));
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  //   getMe(profileId);
  // }, [profileId]);

  useEffect(() => {
    tg.ready();

    tg.expand();

    tg.enableClosingConfirmation();
  }, []);

  // useEffect(() => {
  //   const getMeByChatId = async () => {
  //     try {
  //       const res = await axios.post(
  //         `${process.env.REACT_APP_API_BASE}/get-me-by-chat-id`,
  //         {
  //           chatId: JSON.parse(tgConverted?.user)?.id,
  //         }
  //       );

  //       if (res.data.status == 200 && res.data.isRegistered) {
  //         localStorage.setItem("user", JSON.stringify(res.data.account));
  //       } else {
  //         localStorage.setItem("user", JSON.stringify("{}"));
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  //   getMeByChatId();
  // }, [JSON.parse(tgConverted?.user)?.id]);

  useEffect(() => {
    dispatch(setCartReducer(cart));
    axios
      .get(`${process.env.REACT_APP_API_BASE}/get-data`)
      .then((res) => {
        dispatch(setBaseData(res.data));
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const onClose = () => {
    tg.close();
  };

  return (
    <>
      <div className="App">
        <ScrollToTop />
        <RouterMain />
        {/* <header className="App-header">
        <button onClick={onClose}>Close</button>
        <button onClick={onClose}>Close</button>
      </header> */}
      </div>
    </>
  );
}

export default App;
