import clsx from "clsx";
import * as Yup from "yup";
import axios from "axios";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { HeaderTitle } from "../../components";
import { IsValidPhoneFormat, clearPhoneNumber } from "../../helpers/general";
import { ReactComponent as LogoIcon } from "../../assets/images/logo-2.svg";

function Login() {
  const intl = useIntl();
  let navigate = useNavigate();
  const [code, setCode] = useState(false);

  const validationSchema = Yup.object().shape({
    phone: Yup.string().test(
      "phone-format",
      intl.formatMessage({ id: "COMMON.INCORRECT_PHONE_FORMAT" }),
      function (value) {
        return !!IsValidPhoneFormat(value);
      }
    ),
  });

  let initialValues = {
    code: null,
    phone: "+998",
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      let phone = "";
      if (values.phone.length > 4) {
        phone = clearPhoneNumber(values.phone);
      }

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_BASE}/send-code`,
          {
            phone: phone,
          }
        );

        if (res.data.isRegistered) {
          setCode(true);
          return;
        }

        navigate(`/registration?phone=${phone}`);
      } catch (err) {
        console.log("error", err);
        return;
      }
      setSubmitting(false);
    },
  });

  const sendCode = async () => {
    let phone = "";
    if (formik.values.phone.length > 4) {
      phone = clearPhoneNumber(formik.values.phone);
    }

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE}/check-code`,
        {
          phone: phone,
          code: formik.values.code,
        }
      );

      if (res.data.status != 200) {
        withReactContent(Swal).fire({
          position: "top-end",
          icon: "error",
          title: intl.formatMessage({ id: "COMMON.INCORRECT_CONFIRM_CODE" }),
          showConfirmButton: false,
          timer: 1500,
        });

        return;
      }
      localStorage.setItem("user", JSON.stringify(res.data.account));
      // // setUser(res.data.account);
      navigate("/placing-order");
    } catch (err) {
      console.log("error", err);
      return;
    }
  };

  return (
    <div className="es-login">
      <div className="container">
        <HeaderTitle
          // title={intl.formatMessage({ id: "COMMON.LOGIN" })}
          basket={false}
          link={true}
          linkUrl={""}
        />
        <form>
          <LogoIcon />
          <h3>{intl.formatMessage({ id: "COMMON.LOGIN_ACC" })}</h3>
          <p className="es-form-description">
            {intl.formatMessage({ id: "COMMON.LOGIN_TEXT" })}
          </p>
          <div className="input-group mb-3">
            <InputMask
              className={clsx(
                "es-input-white form-control",
                { "is-invalid": formik.touched.phone && formik.errors.phone },
                {
                  "is-valid": formik.touched.phone && !formik.errors.phone,
                }
              )}
              mask="+999 (99) 999-99-99"
              // @ts-ignore:
              onChange={(e) =>
                formik.setFieldValue("phone", e.currentTarget.value)
              }
              value={formik.values.phone}
              autoComplete="off"
              disabled={code}
            />
          </div>
          {code && (
            <div className="input-group mb-3">
              <input
                type="number"
                {...formik.getFieldProps("code")}
                className={clsx(
                  "es-input-white form-control",
                  { "is-invalid": formik.touched.code && formik.errors.code },
                  {
                    "is-valid": formik.touched.code && !formik.errors.code,
                  }
                )}
                placeholder={intl.formatMessage({ id: "COMMON.CONFIRM_CODE" })}
              />
            </div>
          )}
          <div className="es-window-button">
            <div className="container">
              {code ? (
                <button
                  className="es-btn-main btn w-100 justify-content-center"
                  type="button"
                  onClick={sendCode}
                >
                  {intl.formatMessage({ id: "COMMON.FURTHER" })}
                </button>
              ) : (
                <button
                  className="es-btn-main btn w-100 justify-content-center"
                  type="button"
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  {intl.formatMessage({ id: "COMMON.SEND_CODE" })}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      {/* <div className="es-window-button">
        <div className="container">
          {code ? (
            <button
              className="es-btn-main btn"
              type="button"
              onClick={sendCode}
            >
              {intl.formatMessage({ id: "COMMON.FURTHER" })}
            </button>
          ) : (
            <button
              className="es-btn-main btn"
              type="button"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {intl.formatMessage({ id: "COMMON.SEND_CODE" })}
            </button>
          )}
        </div>
      </div> */}
    </div>
  );
}

export default Login;
