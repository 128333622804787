import axios from "axios";
import * as Yup from "yup";
import moment from "moment";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { HeaderTitle, Loader } from "../../components";

// ICONS
import { ReactComponent as TickSquareIcon } from "../../assets/images/complete.svg";
// import { ReactComponent as TickSquareIcon } from "../../assets/icons/tick-square.svg";

function Complete() {
  const intl = useIntl();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  let id = searchParams.get("id") || "";
  let paymentUrl = searchParams.get("paymentUrl") || "";

  // STATES
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!id) return;

    axios
      .post(`${process.env.REACT_APP_API_BASE}/get-order-by-id`, {
        id: id,
      })
      .then((res) => {
        setOrder(res.data.order || {});
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [id]);

  // METHODS
  const NotifactionComponent = () => {
    return (
      <div className="d-flex flex-column flex-column-fluid h-100">
        <div className="es-notifation">
          <TickSquareIcon />
          <p>
            {intl.formatMessage({
              id: "COMMON.ACCEPTED_TEXT",
            })}
          </p>
          <h5>
            {intl.formatMessage({
              id: "COMMON.INFO_ABOUT_ORDER",
            })}
            :
          </h5>
          <div className="es-item d-flex">
            <p>
              {intl.formatMessage({
                id: "COMMON.ORDER_NUMBER",
              })}
              :
            </p>
            <h3>#{order.id}</h3>
          </div>
          <div className="es-item d-flex">
            <p>
              {intl.formatMessage({
                id: "COMMON.ORDER_DATE",
              })}
              :
            </p>
            <h3>{moment(order.date).format("DD-MM-YYYY")}</h3>
          </div>

          {/* {paymentUrl && order.is_paid == 0 && (
            <a
              className="es-btn-secondary btn w-100 justify-content-center"
              href={paymentUrl}
            >
              {intl.formatMessage({
                id: "COMMON.PAY",
              })}
            </a>
          )} */}
        </div>
        <div className="es-window-button">
          <div className="container">
            <button
              className="es-btn-main btn w-100 justify-content-center"
              type="button"
              onClick={() => navigate("/")}
            >
              {intl.formatMessage({
                id: "COMMON.TO_THE_MAIN_PAGE",
              })}
            </button>
          </div>
        </div>
      </div>
    );
  };

  if (loading) return <Loader />;

  return (
    <div className="es-placing-order">
      <div className="container">
        <HeaderTitle
          title={intl.formatMessage({ id: "COMMON.ORDER_IS_ACCEPTED" })}
          link={true}
          linkUrl={"/"}
          basket={false}
        />

        {NotifactionComponent()}
      </div>
    </div>
  );
}

export default Complete;
