import React from "react";
import { useIntl } from "react-intl";
import { HeaderTitle } from "../../components";
import { useSelector } from "react-redux";
import { useLang } from "../../i18n/Metronici18n";

function ShippingPayment() {
  const lang = useLang();
  const intl = useIntl();

  const settings = useSelector((state) => state.base.settings);

  return (
    <div className="es-shipping">
      <div className="container">
        <HeaderTitle
          title={intl.formatMessage({ id: "COMMON.SHIPPING_AND_PAYMENT" })}
          link={true}
          linkUrl={"/profile"}
        />
        <div
          className="es-shipping-texts"
          dangerouslySetInnerHTML={{
            __html: (
              settings.find((x) => x.key == `delivery_text_webview_` + lang) ||
              {}
            ).value,
          }}
        ></div>
      </div>
    </div>
  );
}

export default ShippingPayment;
