import React from "react";
import {
  convertToPrice,
  getMediaPATH,
  getProductName,
} from "../../helpers/general";
import { Link, useSearchParams } from "react-router-dom";
import { useIntl } from "react-intl";

function Card(props) {
  const intl = useIntl();
  let [searchParams, setSearchParams] = useSearchParams();
  let categoryId = searchParams.get("categoryId") || "";

  return (
    <div className="es-card position-relative">
      <div className="es-card-image position-relative">
        <Link
          to={`/product/${props.product.id}?categoryId=${categoryId}`}
          state={{ state: props.state }}
        >
          <div className="es-absolute-box">
            {props.product.discount_percent > 0 && (
              <div className="es-card-sale">
                {props.product.discount_percent}%
              </div>
            )}
            {props.product.is_new_collection == 1 && (
              <div className="es-card-new">
                {intl.formatMessage({ id: "COMMON.NEW" })}
              </div>
            )}
          </div>
          <img
            src={
              (props.product || {}).url
                ? (props.product || {}).url
                : (((props.product || {}).images || [])[0] || {}).url
              //   getMediaPATH(
              //   "products",
              //   (props.product || {}).image
              //     ? (props.product || {}).image
              //     : (((props.product || {}).images || [])[0] || {}).image
              // )
            }
            alt={getProductName(props.product)}
          />
        </Link>
      </div>
      <div className="es-card-description">
        <p className="es-card-code">
          {intl.formatMessage({ id: "COMMON.ARTICLE" })}:{" "}
          {props.product.article}
        </p>
        <h4 className="es-card-title">
          {getProductName(
            props.product,
            intl.formatMessage({ id: "COMMON.LOCAL_PRODUCT" })
          )}
        </h4>
        {props.product.discount_percent > 0 && (
          <p className="es-card-sale-price">
            <span>
              {convertToPrice(Math.round(props.product.retail_price))}
            </span>
            {intl.formatMessage({ id: "COMMON.SUM" })}
          </p>
        )}
        <h3
          className={`es-card-price ${
            props.product.discount_percent > 0 && "active"
          }`}
        >
          {convertToPrice(Math.round(props.product.current_price))}{" "}
          {intl.formatMessage({ id: "COMMON.SUM" })}
        </h3>
      </div>
    </div>
  );
}

export default Card;
