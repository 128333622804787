import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import "swiper/css";
import "swiper/css/pagination";
import "./assets/fonts/stylesheet.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./stores/store";
import { MetronicI18nProvider } from "./i18n/Metronici18n";
import { I18nProvider } from "./i18n/I18nProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <MetronicI18nProvider>
    <I18nProvider>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </I18nProvider>
  </MetronicI18nProvider>
  // </React.StrictMode>
);

reportWebVitals();
