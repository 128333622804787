import React, { useEffect, useState } from "react";
import { HeaderTitle } from "../../components";

import { Link } from "react-router-dom";
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as InfoCircleIcon } from "../../assets/icons/info-circle.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/profile.svg";
import { ReactComponent as MessagesIcon } from "../../assets/icons/messages.svg";
import { ReactComponent as GlobalIcon } from "../../assets/icons/global.svg";
import { ReactComponent as ReceiptItemIcon } from "../../assets/icons/receipt-item.svg";
import { useIntl } from "react-intl";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import axios from "axios";
import { convertToPrice } from "../../helpers/general";

function Profile() {
  const intl = useIntl();
  const [user, setUser] = useLocalStorage("user", {});

  const [discount, setDiscount] = useState(0);
  const [nextBalance, setNextBalance] = useState(0);
  const [discountPercentNext, setDiscountPercentNext] = useState(0);

  let infos = [
    {
      icon: <ProfileIcon />,
      active: false,
      url: "profile-info",
      title: intl.formatMessage({ id: "COMMON.PERSONAL_PROFILE" }),
    },
    {
      icon: <GlobalIcon />,
      active: false,
      url: "language",
      title: intl.formatMessage({ id: "COMMON.LANGUAGE" }),
    },
    {
      icon: <ReceiptItemIcon />,
      active: false,
      url: "history-orders",
      title: intl.formatMessage({ id: "COMMON.HISTORY_ORDERS" }),
    },
    {
      icon: <InfoCircleIcon />,
      active: false,
      url: "shipping-payment",
      title: intl.formatMessage({ id: "COMMON.SHIPPING_AND_PAYMENT" }),
    },
    // {
    //   icon: <ProfileIcon />,
    //   active: false,
    //   url: "discount-program",
    //   title: intl.formatMessage({ id: "COMMON.DISCOUNT_PROGRAM" }),
    // },
  ];

  async function getDiscountProgram() {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE}/get-discount-program`,
        {
          id: user.id,
        }
      );

      setDiscount(res.data.balance.discount_percent || 0);
      setNextBalance(res.data.balance.remaining_sum || 0);
      setDiscountPercentNext(res.data.balance.discount_percent_next || 0);
    } catch (err) {
      if ((err.response || {}).status == 404) {
        localStorage.removeItem("user");
        window.location.reload();
      }
    }
  }

  useEffect(() => {
    // if (!user.id) return;
    getDiscountProgram();
  }, [user]);

  return (
    <div className="es-profile">
      <div className="container">
        <HeaderTitle
          title={intl.formatMessage({ id: "COMMON.PROFILE" })}
          link={true}
          linkUrl="/"
        />
        <ul>
          {infos.map((info) => (
            <Link to={`/${info.url}`}>
              <li>
                <p>
                  {info.icon} {info.title}
                </p>
                <ArrowDownIcon />
              </li>
            </Link>
          ))}

          <a href={`https://t.me/NIKEUZBN1`} target="_blank">
            <li>
              <p>
                {<MessagesIcon />}{" "}
                {intl.formatMessage({
                  id: "COMMON.ONLINE_CONSULTATION",
                })}
              </p>
              <ArrowDownIcon />
            </li>
          </a>
        </ul>

        <div className="es-discount-wrapper p-3">
          <h5 className="mb-3">
            {intl.formatMessage({ id: "COMMON.DISCOUNT_PROGRAM" })}
          </h5>
          <div className="mb-3">
            {intl.formatMessage({ id: "COMMON.YOUR_DISCOUNT" })} {discount} %
          </div>
          <div>
            {intl.formatMessage({ id: "COMMON.FOR_GET_DISCOUNT_PERCENT" })}{" "}
            {discountPercentNext}%,
          </div>
          <div>
            {intl.formatMessage({ id: "COMMON.FOR_GET_DISCOUNT_PERCENT2" })}{" "}
            {convertToPrice(nextBalance)}{" "}
            {intl.formatMessage({ id: "COMMON.SUM" })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
