import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";

function PrivateRoute() {
  const location = useLocation();
  const [user, setUser] = useLocalStorage("user", {});

  if (!(user || {}).id) {
    return <Navigate to={"/login"} state={{ from: location }} replace />;
  }
  return <Outlet />;
}

export default PrivateRoute;
