import clsx from "clsx";
import * as Yup from "yup";
import axios from "axios";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import InputMask from "react-input-mask";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { HeaderTitle } from "../../components";
import { IsValidPhoneFormat, clearPhoneNumber } from "../../helpers/general";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useLang } from "../../i18n/Metronici18n";

function Registration() {
  const intl = useIntl();
  const locale = useLang();
  const [user, setUser] = useLocalStorage("user", {});
  const navigate = useNavigate();

  let [searchParams, setSearchParams] = useSearchParams();
  let phone = searchParams.get("phone") || "+998";

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    date: Yup.date(),
    gender: Yup.boolean(),
    phone: Yup.string().test(
      "phone-format",
      intl.formatMessage({ id: "COMMON.INCORRECT_PHONE_FORMAT" }),
      function (value) {
        return !!IsValidPhoneFormat(value);
      }
    ),
    code: Yup.number().min(4, "Минимум 4 символ(ов)"),
  });

  let initialValues = {
    name: "",
    date: "",
    gender: true,
    code: null,
    phone: "+998",
  };

  let tgConverted = JSON.parse(
    '{"' +
      (((window.Telegram || {}).WebApp || {}).initData || "")
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}',
    function (key, value) {
      return key === "" ? value : decodeURIComponent(value);
    }
  );
  // let tgConverted = {};

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      let phone = "";
      if (values.phone.length > 4) {
        phone = clearPhoneNumber(values.phone);
      }

      // CHECK CODE
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_BASE}/check-code`,
          {
            phone: phone,
            code: formik.values.code,
          }
        );
        if (res.data.status != 200) {
          withReactContent(Swal).fire({
            position: "top-end",
            icon: "error",
            title: intl.formatMessage({ id: "COMMON.INCORRECT_CONFIRM_CODE" }),
            showConfirmButton: false,
            timer: 1500,
          });

          return;
        }
      } catch (err) {
        console.log("error", err);
        return;
      }

      // REGISTER
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_BASE}/register`,
          {
            phone: phone,
            code: values.code,
            name: values.name,
            birthdate: values.date,
            sex: values.gender ? 1 : 2,
            chat_id: JSON.parse(tgConverted?.user)?.id,
            language: locale || "ru",
          }
        );
        if (res.data.status != 200) {
          withReactContent(Swal).fire({
            position: "top-end",
            icon: "error",
            title: intl.formatMessage({ id: "COMMON.INCORRECT_CONFIRM_CODE" }),
            showConfirmButton: false,
            timer: 1500,
          });

          return;
        }
        console.log(res);
        // setUser(res.data.account);
        localStorage.setItem("user", JSON.stringify(res.data.account));
        navigate("/");
      } catch (err) {
        console.log("error", err);
        return;
      }
      setSubmitting(false);
    },
  });

  useEffect(() => {
    formik.setFieldValue("phone", phone);
  }, [phone]);

  return (
    <div className="es-registration">
      <div className="container">
        <HeaderTitle basket={false} link={true} linkUrl={""} />
        <form>
          <div className="input-group mb-3">
            <input
              type="text"
              {...formik.getFieldProps("name")}
              className={clsx(
                "es-input-white form-control",
                { "is-invalid": formik.touched.name && formik.errors.name },
                {
                  "is-valid": formik.touched.name && !formik.errors.name,
                }
              )}
              placeholder={intl.formatMessage({ id: "COMMON.FULL_NAME" })}
            />
          </div>
          <div className="input-group mb-3">
            <input
              type="text"
              onChange={(e) => console.log(e.target.value)}
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
              {...formik.getFieldProps("date")}
              className={clsx(
                "es-input-white form-control",
                { "is-invalid": formik.touched.date && formik.errors.date },
                {
                  "is-valid": formik.touched.date && !formik.errors.date,
                }
              )}
              placeholder={intl.formatMessage({ id: "COMMON.BIRTHDATE" })}
            />
          </div>
          <div className="input-group mb-3">
            <InputMask
              className={clsx(
                "es-input-white form-control",
                { "is-invalid": formik.touched.phone && formik.errors.phone },
                {
                  "is-valid": formik.touched.phone && !formik.errors.phone,
                }
              )}
              mask="+999 (99) 999-99-99"
              // @ts-ignore:
              onChange={(e) =>
                formik.setFieldValue("phone", e.currentTarget.value)
              }
              value={formik.values.phone}
              autoComplete="off"
              disabled
            />
          </div>
          <div className="input-group mb-3">
            <input
              type="text"
              {...formik.getFieldProps("code")}
              className={clsx(
                "es-input-white form-control",
                { "is-invalid": formik.touched.code && formik.errors.code },
                {
                  "is-valid": formik.touched.code && !formik.errors.code,
                }
              )}
              placeholder={intl.formatMessage({ id: "COMMON.CONFIRM_CODE" })}
            />
          </div>
          <div className="input-group mb-3">
            <div className="es-registration-btns">
              <button
                onClick={() => formik.setFieldValue("gender", true)}
                type="button"
                className={clsx(
                  "btn justify-content-center w-100 me-1",
                  { "es-btn-main": formik.values.gender },
                  {
                    "es-btn-secondary ": !formik.values.gender,
                  }
                )}
              >
                {intl.formatMessage({ id: "COMMON.MALE" })}
              </button>
              <button
                onClick={() => formik.setFieldValue("gender", false)}
                type="button"
                className={clsx(
                  "btn justify-content-center w-100",
                  { "es-btn-secondary": formik.values.gender },
                  {
                    "es-btn-main": !formik.values.gender,
                  }
                )}
              >
                {intl.formatMessage({ id: "COMMON.FEMALE" })}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="es-window-button">
        <div className="container">
          <button
            className="es-btn-main btn w-100 justify-content-center"
            type="button"
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            {intl.formatMessage({ id: "COMMON.FURTHER" })}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Registration;
