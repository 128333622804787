import { Routes, Route } from "react-router-dom";
import {
  Basket,
  HistoryOrders,
  Home,
  Language,
  PageNotFound,
  PlacingOrder,
  Product,
  Products,
  Profile,
  Registration,
  Search,
  ShippingPayment,
  ProfileInfo,
  Login,
  DiscountProgram,
} from "../pages";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { Layout } from "../components";
import Complete from "../pages/complete";

function RouterMain() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="product/:id" element={<Product />} />
        <Route path="products" element={<Products />} />
        <Route path="basket" element={<Basket />} />

        <Route element={<PrivateRoute />}>
          <Route path="profile" element={<Profile />} />
          <Route path="placing-order" element={<PlacingOrder />} />
          <Route path="complete" element={<Complete />} />
          <Route path="shipping-payment" element={<ShippingPayment />} />
          <Route path="history-orders" element={<HistoryOrders />} />
          <Route path="profile-info" element={<ProfileInfo />} />
          <Route path="search" element={<Search />} />
          <Route path="language" element={<Language />} />
          <Route path="discount-program" element={<DiscountProgram />} />
        </Route>
        <Route path="login" element={<Login />} />

        <Route element={<PublicRoute />}>
          <Route path="registration" element={<Registration />} />
        </Route>
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default RouterMain;
