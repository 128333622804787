import clsx from "clsx";
import * as Yup from "yup";
import axios from "axios";
import moment from "moment";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { HeaderTitle, Loader } from "../../components";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { convertToPrice } from "../../helpers/general";

function DiscountProgram() {
  const intl = useIntl();
  const [user, setUser] = useLocalStorage("user", {});

  const [discount, setDiscount] = useState(0);
  const [nextBalance, setNextBalance] = useState(0);
  let [loading, setLoading] = useState(true);

  async function getData() {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE}/get-discount-program`,
        {
          id: user.id,
        }
      );

      setDiscount(res.data.balance.discount_percent || 0);
      setNextBalance(res.data.balance.remaining_sum || 0);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err.response.status == 404) {
        localStorage.removeItem("user");
        window.location.reload();
      }
    }
  }

  useEffect(() => {
    getData();
  }, []);

  if (loading) return <Loader />;

  return (
    <div className="es-language">
      <div className="container">
        <HeaderTitle
          title={intl.formatMessage({ id: "COMMON.DISCOUNT_PROGRAM" })}
          link={true}
          linkUrl={"/profile"}
        />

        <div className="es-language-btns align-items-stretch">
          <div className="d-flex justify-content-between">
            <span className="fw-bold">
              {intl.formatMessage({ id: "COMMON.YOUR_DISCOUNT" })}
            </span>
            <span>{discount} %</span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="fw-bold">
              {intl.formatMessage({ id: "COMMON.REMAINING_SUM" })}
            </span>
            <span>
              {convertToPrice(nextBalance)}{" "}
              {intl.formatMessage({ id: "COMMON.SUM" })}{" "}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiscountProgram;
