import clsx from "clsx";
import * as Yup from "yup";
import axios from "axios";
import moment from "moment";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { HeaderTitle, Loader } from "../../components";
import { useLocalStorage } from "../../hooks/useLocalStorage";

function ProfileInfo() {
  const intl = useIntl();
  const [user, setUser] = useLocalStorage("user", {});
  let [loading, setLoading] = useState(true);

  // FORMIK
  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    birthdate: Yup.date(),
    gender: Yup.boolean(),
  });

  let initialValues = {
    id: 0,
    name: "",
    birthdate: "",
    gender: 1,
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_BASE}/update-account`,
          {
            id: values.id,
            name: values.name,
            birthdate: values.birthdate,
            sex: values ? 1 : 2,
          }
        );
      } catch (err) {
        console.log("error", err);
        return;
      }
      setSubmitting(false);
    },
  });

  async function getData() {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_BASE}/get-me`, {
        id: user.id,
      });

      formik.setFieldValue("id", res.data.account.id);
      formik.setFieldValue("name", res.data.account.name);
      formik.setFieldValue(
        "birthdate",
        moment(res.data.account.birthdate).format("YYYY-MM-DD")
      );
      formik.setFieldValue("gender", res.data.account.sex == 1 ? true : false);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  if (loading) return <Loader />;

  return (
    <div className="es-profile-info">
      <div className="container">
        <HeaderTitle
          title={intl.formatMessage({ id: "COMMON.PERSONAL_PROFILE" })}
          link={true}
          linkUrl={"/profile"}
        />
        <form>
          <div className="input-group mb-3">
            <input
              type="text"
              {...formik.getFieldProps("name")}
              className={clsx(
                "es-input-white form-control",
                { "is-invalid": formik.touched.name && formik.errors.name },
                {
                  "is-valid": formik.touched.name && !formik.errors.name,
                }
              )}
              placeholder={intl.formatMessage({ id: "COMMON.FULL_NAME" })}
            />
          </div>
          <div className="input-group mb-3">
            <input
              type="date"
              {...formik.getFieldProps("birthdate")}
              className={clsx(
                "es-input-white form-control",
                {
                  "is-invalid":
                    formik.touched.birthdate && formik.errors.birthdate,
                },
                {
                  "is-valid":
                    formik.touched.birthdate && !formik.errors.birthdate,
                }
              )}
            />
          </div>
          <div className="input-group mb-3">
            <div className="es-profile-info-btns">
              <button
                onClick={() => formik.setFieldValue("gender", true)}
                type="button"
                className={clsx(
                  "btn justify-content-center btn-100",
                  { "es-btn-main": formik.values.gender },
                  {
                    "es-btn-secondary ": !formik.values.gender,
                  }
                )}
              >
                {intl.formatMessage({ id: "COMMON.MALE" })}
              </button>
              <button
                onClick={() => formik.setFieldValue("gender", false)}
                type="button"
                className={clsx(
                  "btn justify-content-center btn-100",
                  { "es-btn-secondary": formik.values.gender },
                  {
                    "es-btn-main": !formik.values.gender,
                  }
                )}
              >
                {intl.formatMessage({ id: "COMMON.FEMALE" })}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="es-window-button">
        <div className="container">
          <button
            className="es-btn-main btn w-100 justify-content-center"
            type="button"
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            {intl.formatMessage({ id: "COMMON.SAVE" })}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProfileInfo;
